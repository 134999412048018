// source: coins_entity.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var zengin_entity_pb = require('./zengin_entity_pb.js');
goog.object.extend(proto, zengin_entity_pb);
goog.exportSymbol('proto.jp.singcolor.CoinAmount', null, global);
goog.exportSymbol('proto.jp.singcolor.CoinBalance', null, global);
goog.exportSymbol('proto.jp.singcolor.CoinEarningRate', null, global);
goog.exportSymbol('proto.jp.singcolor.CoinEarningRate.Star', null, global);
goog.exportSymbol('proto.jp.singcolor.CoinPurchasePlan', null, global);
goog.exportSymbol('proto.jp.singcolor.MembershipType', null, global);
goog.exportSymbol('proto.jp.singcolor.SpecificLiverCoin', null, global);
goog.exportSymbol('proto.jp.singcolor.StripeVbanAccount', null, global);
goog.exportSymbol('proto.jp.singcolor.WebCoinBankTransfer', null, global);
goog.exportSymbol('proto.jp.singcolor.WebCoinPurchasePlan', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.CoinBalance = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.CoinBalance, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.jp.singcolor.CoinBalance.displayName = 'proto.jp.singcolor.CoinBalance';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.SpecificLiverCoin = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.SpecificLiverCoin, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.jp.singcolor.SpecificLiverCoin.displayName = 'proto.jp.singcolor.SpecificLiverCoin';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.CoinAmount = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.CoinAmount, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.jp.singcolor.CoinAmount.displayName = 'proto.jp.singcolor.CoinAmount';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.CoinPurchasePlan = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.CoinPurchasePlan, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.jp.singcolor.CoinPurchasePlan.displayName = 'proto.jp.singcolor.CoinPurchasePlan';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.WebCoinPurchasePlan = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.WebCoinPurchasePlan, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.jp.singcolor.WebCoinPurchasePlan.displayName = 'proto.jp.singcolor.WebCoinPurchasePlan';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.CoinEarningRate = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.CoinEarningRate, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.jp.singcolor.CoinEarningRate.displayName = 'proto.jp.singcolor.CoinEarningRate';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.CoinEarningRate.Star = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.CoinEarningRate.Star, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.jp.singcolor.CoinEarningRate.Star.displayName = 'proto.jp.singcolor.CoinEarningRate.Star';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.WebCoinBankTransfer = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.WebCoinBankTransfer, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.jp.singcolor.WebCoinBankTransfer.displayName = 'proto.jp.singcolor.WebCoinBankTransfer';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.StripeVbanAccount = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.StripeVbanAccount, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.jp.singcolor.StripeVbanAccount.displayName = 'proto.jp.singcolor.StripeVbanAccount';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.jp.singcolor.CoinBalance.prototype.toObject = function(opt_includeInstance) {
  return proto.jp.singcolor.CoinBalance.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.jp.singcolor.CoinBalance} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.CoinBalance.toObject = function(includeInstance, msg) {
  var f, obj = {
    userId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    totalAmount: (f = msg.getTotalAmount()) && proto.jp.singcolor.CoinAmount.toObject(includeInstance, f),
    paidAmount: (f = msg.getPaidAmount()) && proto.jp.singcolor.CoinAmount.toObject(includeInstance, f),
    fromDiamondAmount: (f = msg.getFromDiamondAmount()) && proto.jp.singcolor.CoinAmount.toObject(includeInstance, f),
    discountAmount: (f = msg.getDiscountAmount()) && proto.jp.singcolor.CoinAmount.toObject(includeInstance, f),
    doublePaidAmount: (f = msg.getDoublePaidAmount()) && proto.jp.singcolor.CoinAmount.toObject(includeInstance, f),
    freeAmount: (f = msg.getFreeAmount()) && proto.jp.singcolor.CoinAmount.toObject(includeInstance, f),
    createdAt: jspb.Message.getFieldWithDefault(msg, 5, 0),
    updatedAt: jspb.Message.getFieldWithDefault(msg, 6, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.CoinBalance}
 */
proto.jp.singcolor.CoinBalance.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.jp.singcolor.CoinBalance;
  return proto.jp.singcolor.CoinBalance.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.CoinBalance} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.CoinBalance}
 */
proto.jp.singcolor.CoinBalance.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserId(value);
      break;
    case 2:
      var value = new proto.jp.singcolor.CoinAmount;
      reader.readMessage(value,proto.jp.singcolor.CoinAmount.deserializeBinaryFromReader);
      msg.setTotalAmount(value);
      break;
    case 3:
      var value = new proto.jp.singcolor.CoinAmount;
      reader.readMessage(value,proto.jp.singcolor.CoinAmount.deserializeBinaryFromReader);
      msg.setPaidAmount(value);
      break;
    case 7:
      var value = new proto.jp.singcolor.CoinAmount;
      reader.readMessage(value,proto.jp.singcolor.CoinAmount.deserializeBinaryFromReader);
      msg.setFromDiamondAmount(value);
      break;
    case 8:
      var value = new proto.jp.singcolor.CoinAmount;
      reader.readMessage(value,proto.jp.singcolor.CoinAmount.deserializeBinaryFromReader);
      msg.setDiscountAmount(value);
      break;
    case 9:
      var value = new proto.jp.singcolor.CoinAmount;
      reader.readMessage(value,proto.jp.singcolor.CoinAmount.deserializeBinaryFromReader);
      msg.setDoublePaidAmount(value);
      break;
    case 4:
      var value = new proto.jp.singcolor.CoinAmount;
      reader.readMessage(value,proto.jp.singcolor.CoinAmount.deserializeBinaryFromReader);
      msg.setFreeAmount(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCreatedAt(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUpdatedAt(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.CoinBalance.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.jp.singcolor.CoinBalance.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.CoinBalance} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.CoinBalance.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTotalAmount();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.jp.singcolor.CoinAmount.serializeBinaryToWriter
    );
  }
  f = message.getPaidAmount();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.jp.singcolor.CoinAmount.serializeBinaryToWriter
    );
  }
  f = message.getFromDiamondAmount();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.jp.singcolor.CoinAmount.serializeBinaryToWriter
    );
  }
  f = message.getDiscountAmount();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      proto.jp.singcolor.CoinAmount.serializeBinaryToWriter
    );
  }
  f = message.getDoublePaidAmount();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      proto.jp.singcolor.CoinAmount.serializeBinaryToWriter
    );
  }
  f = message.getFreeAmount();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.jp.singcolor.CoinAmount.serializeBinaryToWriter
    );
  }
  f = message.getCreatedAt();
  if (f !== 0) {
    writer.writeInt64(
      5,
      f
    );
  }
  f = message.getUpdatedAt();
  if (f !== 0) {
    writer.writeInt64(
      6,
      f
    );
  }
};


/**
 * optional string user_id = 1;
 * @return {string}
 */
proto.jp.singcolor.CoinBalance.prototype.getUserId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.jp.singcolor.CoinBalance} returns this
 */
proto.jp.singcolor.CoinBalance.prototype.setUserId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional CoinAmount total_amount = 2;
 * @return {?proto.jp.singcolor.CoinAmount}
 */
proto.jp.singcolor.CoinBalance.prototype.getTotalAmount = function() {
  return /** @type{?proto.jp.singcolor.CoinAmount} */ (
    jspb.Message.getWrapperField(this, proto.jp.singcolor.CoinAmount, 2));
};


/**
 * @param {?proto.jp.singcolor.CoinAmount|undefined} value
 * @return {!proto.jp.singcolor.CoinBalance} returns this
*/
proto.jp.singcolor.CoinBalance.prototype.setTotalAmount = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.CoinBalance} returns this
 */
proto.jp.singcolor.CoinBalance.prototype.clearTotalAmount = function() {
  return this.setTotalAmount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.CoinBalance.prototype.hasTotalAmount = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional CoinAmount paid_amount = 3;
 * @return {?proto.jp.singcolor.CoinAmount}
 */
proto.jp.singcolor.CoinBalance.prototype.getPaidAmount = function() {
  return /** @type{?proto.jp.singcolor.CoinAmount} */ (
    jspb.Message.getWrapperField(this, proto.jp.singcolor.CoinAmount, 3));
};


/**
 * @param {?proto.jp.singcolor.CoinAmount|undefined} value
 * @return {!proto.jp.singcolor.CoinBalance} returns this
*/
proto.jp.singcolor.CoinBalance.prototype.setPaidAmount = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.CoinBalance} returns this
 */
proto.jp.singcolor.CoinBalance.prototype.clearPaidAmount = function() {
  return this.setPaidAmount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.CoinBalance.prototype.hasPaidAmount = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional CoinAmount from_diamond_amount = 7;
 * @return {?proto.jp.singcolor.CoinAmount}
 */
proto.jp.singcolor.CoinBalance.prototype.getFromDiamondAmount = function() {
  return /** @type{?proto.jp.singcolor.CoinAmount} */ (
    jspb.Message.getWrapperField(this, proto.jp.singcolor.CoinAmount, 7));
};


/**
 * @param {?proto.jp.singcolor.CoinAmount|undefined} value
 * @return {!proto.jp.singcolor.CoinBalance} returns this
*/
proto.jp.singcolor.CoinBalance.prototype.setFromDiamondAmount = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.CoinBalance} returns this
 */
proto.jp.singcolor.CoinBalance.prototype.clearFromDiamondAmount = function() {
  return this.setFromDiamondAmount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.CoinBalance.prototype.hasFromDiamondAmount = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional CoinAmount discount_amount = 8;
 * @return {?proto.jp.singcolor.CoinAmount}
 */
proto.jp.singcolor.CoinBalance.prototype.getDiscountAmount = function() {
  return /** @type{?proto.jp.singcolor.CoinAmount} */ (
    jspb.Message.getWrapperField(this, proto.jp.singcolor.CoinAmount, 8));
};


/**
 * @param {?proto.jp.singcolor.CoinAmount|undefined} value
 * @return {!proto.jp.singcolor.CoinBalance} returns this
*/
proto.jp.singcolor.CoinBalance.prototype.setDiscountAmount = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.CoinBalance} returns this
 */
proto.jp.singcolor.CoinBalance.prototype.clearDiscountAmount = function() {
  return this.setDiscountAmount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.CoinBalance.prototype.hasDiscountAmount = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional CoinAmount double_paid_amount = 9;
 * @return {?proto.jp.singcolor.CoinAmount}
 */
proto.jp.singcolor.CoinBalance.prototype.getDoublePaidAmount = function() {
  return /** @type{?proto.jp.singcolor.CoinAmount} */ (
    jspb.Message.getWrapperField(this, proto.jp.singcolor.CoinAmount, 9));
};


/**
 * @param {?proto.jp.singcolor.CoinAmount|undefined} value
 * @return {!proto.jp.singcolor.CoinBalance} returns this
*/
proto.jp.singcolor.CoinBalance.prototype.setDoublePaidAmount = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.CoinBalance} returns this
 */
proto.jp.singcolor.CoinBalance.prototype.clearDoublePaidAmount = function() {
  return this.setDoublePaidAmount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.CoinBalance.prototype.hasDoublePaidAmount = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional CoinAmount free_amount = 4;
 * @return {?proto.jp.singcolor.CoinAmount}
 */
proto.jp.singcolor.CoinBalance.prototype.getFreeAmount = function() {
  return /** @type{?proto.jp.singcolor.CoinAmount} */ (
    jspb.Message.getWrapperField(this, proto.jp.singcolor.CoinAmount, 4));
};


/**
 * @param {?proto.jp.singcolor.CoinAmount|undefined} value
 * @return {!proto.jp.singcolor.CoinBalance} returns this
*/
proto.jp.singcolor.CoinBalance.prototype.setFreeAmount = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.CoinBalance} returns this
 */
proto.jp.singcolor.CoinBalance.prototype.clearFreeAmount = function() {
  return this.setFreeAmount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.CoinBalance.prototype.hasFreeAmount = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional int64 created_at = 5;
 * @return {number}
 */
proto.jp.singcolor.CoinBalance.prototype.getCreatedAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.jp.singcolor.CoinBalance} returns this
 */
proto.jp.singcolor.CoinBalance.prototype.setCreatedAt = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional int64 updated_at = 6;
 * @return {number}
 */
proto.jp.singcolor.CoinBalance.prototype.getUpdatedAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.jp.singcolor.CoinBalance} returns this
 */
proto.jp.singcolor.CoinBalance.prototype.setUpdatedAt = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.jp.singcolor.SpecificLiverCoin.prototype.toObject = function(opt_includeInstance) {
  return proto.jp.singcolor.SpecificLiverCoin.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.jp.singcolor.SpecificLiverCoin} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.SpecificLiverCoin.toObject = function(includeInstance, msg) {
  var f, obj = {
    liverId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    coinAmount: (f = msg.getCoinAmount()) && proto.jp.singcolor.CoinAmount.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.SpecificLiverCoin}
 */
proto.jp.singcolor.SpecificLiverCoin.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.jp.singcolor.SpecificLiverCoin;
  return proto.jp.singcolor.SpecificLiverCoin.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.SpecificLiverCoin} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.SpecificLiverCoin}
 */
proto.jp.singcolor.SpecificLiverCoin.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setLiverId(value);
      break;
    case 2:
      var value = new proto.jp.singcolor.CoinAmount;
      reader.readMessage(value,proto.jp.singcolor.CoinAmount.deserializeBinaryFromReader);
      msg.setCoinAmount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.SpecificLiverCoin.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.jp.singcolor.SpecificLiverCoin.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.SpecificLiverCoin} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.SpecificLiverCoin.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLiverId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCoinAmount();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.jp.singcolor.CoinAmount.serializeBinaryToWriter
    );
  }
};


/**
 * optional string liver_id = 1;
 * @return {string}
 */
proto.jp.singcolor.SpecificLiverCoin.prototype.getLiverId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.jp.singcolor.SpecificLiverCoin} returns this
 */
proto.jp.singcolor.SpecificLiverCoin.prototype.setLiverId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional CoinAmount coin_amount = 2;
 * @return {?proto.jp.singcolor.CoinAmount}
 */
proto.jp.singcolor.SpecificLiverCoin.prototype.getCoinAmount = function() {
  return /** @type{?proto.jp.singcolor.CoinAmount} */ (
    jspb.Message.getWrapperField(this, proto.jp.singcolor.CoinAmount, 2));
};


/**
 * @param {?proto.jp.singcolor.CoinAmount|undefined} value
 * @return {!proto.jp.singcolor.SpecificLiverCoin} returns this
*/
proto.jp.singcolor.SpecificLiverCoin.prototype.setCoinAmount = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.SpecificLiverCoin} returns this
 */
proto.jp.singcolor.SpecificLiverCoin.prototype.clearCoinAmount = function() {
  return this.setCoinAmount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.SpecificLiverCoin.prototype.hasCoinAmount = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.jp.singcolor.CoinAmount.prototype.toObject = function(opt_includeInstance) {
  return proto.jp.singcolor.CoinAmount.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.jp.singcolor.CoinAmount} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.CoinAmount.toObject = function(includeInstance, msg) {
  var f, obj = {
    amount: jspb.Message.getFloatingPointFieldWithDefault(msg, 1, 0.0),
    amountText: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.CoinAmount}
 */
proto.jp.singcolor.CoinAmount.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.jp.singcolor.CoinAmount;
  return proto.jp.singcolor.CoinAmount.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.CoinAmount} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.CoinAmount}
 */
proto.jp.singcolor.CoinAmount.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setAmount(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setAmountText(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.CoinAmount.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.jp.singcolor.CoinAmount.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.CoinAmount} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.CoinAmount.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAmount();
  if (f !== 0.0) {
    writer.writeDouble(
      1,
      f
    );
  }
  f = message.getAmountText();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional double amount = 1;
 * @return {number}
 */
proto.jp.singcolor.CoinAmount.prototype.getAmount = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.jp.singcolor.CoinAmount} returns this
 */
proto.jp.singcolor.CoinAmount.prototype.setAmount = function(value) {
  return jspb.Message.setProto3FloatField(this, 1, value);
};


/**
 * optional string amount_text = 2;
 * @return {string}
 */
proto.jp.singcolor.CoinAmount.prototype.getAmountText = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.jp.singcolor.CoinAmount} returns this
 */
proto.jp.singcolor.CoinAmount.prototype.setAmountText = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.jp.singcolor.CoinPurchasePlan.prototype.toObject = function(opt_includeInstance) {
  return proto.jp.singcolor.CoinPurchasePlan.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.jp.singcolor.CoinPurchasePlan} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.CoinPurchasePlan.toObject = function(includeInstance, msg) {
  var f, obj = {
    planId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    storeProductId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    coinAmount: (f = msg.getCoinAmount()) && proto.jp.singcolor.CoinAmount.toObject(includeInstance, f),
    overpricedCoinAmount: (f = msg.getOverpricedCoinAmount()) && proto.jp.singcolor.CoinAmount.toObject(includeInstance, f),
    overpricedStoreProductId: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.CoinPurchasePlan}
 */
proto.jp.singcolor.CoinPurchasePlan.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.jp.singcolor.CoinPurchasePlan;
  return proto.jp.singcolor.CoinPurchasePlan.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.CoinPurchasePlan} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.CoinPurchasePlan}
 */
proto.jp.singcolor.CoinPurchasePlan.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setPlanId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setStoreProductId(value);
      break;
    case 3:
      var value = new proto.jp.singcolor.CoinAmount;
      reader.readMessage(value,proto.jp.singcolor.CoinAmount.deserializeBinaryFromReader);
      msg.setCoinAmount(value);
      break;
    case 4:
      var value = new proto.jp.singcolor.CoinAmount;
      reader.readMessage(value,proto.jp.singcolor.CoinAmount.deserializeBinaryFromReader);
      msg.setOverpricedCoinAmount(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setOverpricedStoreProductId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.CoinPurchasePlan.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.jp.singcolor.CoinPurchasePlan.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.CoinPurchasePlan} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.CoinPurchasePlan.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPlanId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getStoreProductId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCoinAmount();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.jp.singcolor.CoinAmount.serializeBinaryToWriter
    );
  }
  f = message.getOverpricedCoinAmount();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.jp.singcolor.CoinAmount.serializeBinaryToWriter
    );
  }
  f = message.getOverpricedStoreProductId();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional string plan_id = 1;
 * @return {string}
 */
proto.jp.singcolor.CoinPurchasePlan.prototype.getPlanId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.jp.singcolor.CoinPurchasePlan} returns this
 */
proto.jp.singcolor.CoinPurchasePlan.prototype.setPlanId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string store_product_id = 2;
 * @return {string}
 */
proto.jp.singcolor.CoinPurchasePlan.prototype.getStoreProductId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.jp.singcolor.CoinPurchasePlan} returns this
 */
proto.jp.singcolor.CoinPurchasePlan.prototype.setStoreProductId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional CoinAmount coin_amount = 3;
 * @return {?proto.jp.singcolor.CoinAmount}
 */
proto.jp.singcolor.CoinPurchasePlan.prototype.getCoinAmount = function() {
  return /** @type{?proto.jp.singcolor.CoinAmount} */ (
    jspb.Message.getWrapperField(this, proto.jp.singcolor.CoinAmount, 3));
};


/**
 * @param {?proto.jp.singcolor.CoinAmount|undefined} value
 * @return {!proto.jp.singcolor.CoinPurchasePlan} returns this
*/
proto.jp.singcolor.CoinPurchasePlan.prototype.setCoinAmount = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.CoinPurchasePlan} returns this
 */
proto.jp.singcolor.CoinPurchasePlan.prototype.clearCoinAmount = function() {
  return this.setCoinAmount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.CoinPurchasePlan.prototype.hasCoinAmount = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional CoinAmount overpriced_coin_amount = 4;
 * @return {?proto.jp.singcolor.CoinAmount}
 */
proto.jp.singcolor.CoinPurchasePlan.prototype.getOverpricedCoinAmount = function() {
  return /** @type{?proto.jp.singcolor.CoinAmount} */ (
    jspb.Message.getWrapperField(this, proto.jp.singcolor.CoinAmount, 4));
};


/**
 * @param {?proto.jp.singcolor.CoinAmount|undefined} value
 * @return {!proto.jp.singcolor.CoinPurchasePlan} returns this
*/
proto.jp.singcolor.CoinPurchasePlan.prototype.setOverpricedCoinAmount = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.CoinPurchasePlan} returns this
 */
proto.jp.singcolor.CoinPurchasePlan.prototype.clearOverpricedCoinAmount = function() {
  return this.setOverpricedCoinAmount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.CoinPurchasePlan.prototype.hasOverpricedCoinAmount = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional string overpriced_store_product_id = 5;
 * @return {string}
 */
proto.jp.singcolor.CoinPurchasePlan.prototype.getOverpricedStoreProductId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.jp.singcolor.CoinPurchasePlan} returns this
 */
proto.jp.singcolor.CoinPurchasePlan.prototype.setOverpricedStoreProductId = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.jp.singcolor.WebCoinPurchasePlan.prototype.toObject = function(opt_includeInstance) {
  return proto.jp.singcolor.WebCoinPurchasePlan.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.jp.singcolor.WebCoinPurchasePlan} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.WebCoinPurchasePlan.toObject = function(includeInstance, msg) {
  var f, obj = {
    planId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    planName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    priceJpy: jspb.Message.getFieldWithDefault(msg, 3, 0),
    originalPriceJpy: jspb.Message.getFieldWithDefault(msg, 4, 0),
    coinAmount: (f = msg.getCoinAmount()) && proto.jp.singcolor.CoinAmount.toObject(includeInstance, f),
    acquireStarLv: jspb.Message.getFieldWithDefault(msg, 6, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.WebCoinPurchasePlan}
 */
proto.jp.singcolor.WebCoinPurchasePlan.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.jp.singcolor.WebCoinPurchasePlan;
  return proto.jp.singcolor.WebCoinPurchasePlan.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.WebCoinPurchasePlan} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.WebCoinPurchasePlan}
 */
proto.jp.singcolor.WebCoinPurchasePlan.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setPlanId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPlanName(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPriceJpy(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setOriginalPriceJpy(value);
      break;
    case 5:
      var value = new proto.jp.singcolor.CoinAmount;
      reader.readMessage(value,proto.jp.singcolor.CoinAmount.deserializeBinaryFromReader);
      msg.setCoinAmount(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setAcquireStarLv(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.WebCoinPurchasePlan.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.jp.singcolor.WebCoinPurchasePlan.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.WebCoinPurchasePlan} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.WebCoinPurchasePlan.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPlanId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPlanName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getPriceJpy();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getOriginalPriceJpy();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getCoinAmount();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.jp.singcolor.CoinAmount.serializeBinaryToWriter
    );
  }
  f = message.getAcquireStarLv();
  if (f !== 0) {
    writer.writeInt64(
      6,
      f
    );
  }
};


/**
 * optional string plan_id = 1;
 * @return {string}
 */
proto.jp.singcolor.WebCoinPurchasePlan.prototype.getPlanId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.jp.singcolor.WebCoinPurchasePlan} returns this
 */
proto.jp.singcolor.WebCoinPurchasePlan.prototype.setPlanId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string plan_name = 2;
 * @return {string}
 */
proto.jp.singcolor.WebCoinPurchasePlan.prototype.getPlanName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.jp.singcolor.WebCoinPurchasePlan} returns this
 */
proto.jp.singcolor.WebCoinPurchasePlan.prototype.setPlanName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int64 price_jpy = 3;
 * @return {number}
 */
proto.jp.singcolor.WebCoinPurchasePlan.prototype.getPriceJpy = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.jp.singcolor.WebCoinPurchasePlan} returns this
 */
proto.jp.singcolor.WebCoinPurchasePlan.prototype.setPriceJpy = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int64 original_price_jpy = 4;
 * @return {number}
 */
proto.jp.singcolor.WebCoinPurchasePlan.prototype.getOriginalPriceJpy = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.jp.singcolor.WebCoinPurchasePlan} returns this
 */
proto.jp.singcolor.WebCoinPurchasePlan.prototype.setOriginalPriceJpy = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional CoinAmount coin_amount = 5;
 * @return {?proto.jp.singcolor.CoinAmount}
 */
proto.jp.singcolor.WebCoinPurchasePlan.prototype.getCoinAmount = function() {
  return /** @type{?proto.jp.singcolor.CoinAmount} */ (
    jspb.Message.getWrapperField(this, proto.jp.singcolor.CoinAmount, 5));
};


/**
 * @param {?proto.jp.singcolor.CoinAmount|undefined} value
 * @return {!proto.jp.singcolor.WebCoinPurchasePlan} returns this
*/
proto.jp.singcolor.WebCoinPurchasePlan.prototype.setCoinAmount = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.WebCoinPurchasePlan} returns this
 */
proto.jp.singcolor.WebCoinPurchasePlan.prototype.clearCoinAmount = function() {
  return this.setCoinAmount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.WebCoinPurchasePlan.prototype.hasCoinAmount = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional int64 acquire_star_lv = 6;
 * @return {number}
 */
proto.jp.singcolor.WebCoinPurchasePlan.prototype.getAcquireStarLv = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.jp.singcolor.WebCoinPurchasePlan} returns this
 */
proto.jp.singcolor.WebCoinPurchasePlan.prototype.setAcquireStarLv = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.jp.singcolor.CoinEarningRate.prototype.toObject = function(opt_includeInstance) {
  return proto.jp.singcolor.CoinEarningRate.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.jp.singcolor.CoinEarningRate} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.CoinEarningRate.toObject = function(includeInstance, msg) {
  var f, obj = {
    webCoinPurchaseRate: jspb.Message.getFieldWithDefault(msg, 1, ""),
    starLv1: (f = msg.getStarLv1()) && proto.jp.singcolor.CoinEarningRate.Star.toObject(includeInstance, f),
    starLv2: (f = msg.getStarLv2()) && proto.jp.singcolor.CoinEarningRate.Star.toObject(includeInstance, f),
    starLv3: (f = msg.getStarLv3()) && proto.jp.singcolor.CoinEarningRate.Star.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.CoinEarningRate}
 */
proto.jp.singcolor.CoinEarningRate.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.jp.singcolor.CoinEarningRate;
  return proto.jp.singcolor.CoinEarningRate.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.CoinEarningRate} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.CoinEarningRate}
 */
proto.jp.singcolor.CoinEarningRate.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setWebCoinPurchaseRate(value);
      break;
    case 2:
      var value = new proto.jp.singcolor.CoinEarningRate.Star;
      reader.readMessage(value,proto.jp.singcolor.CoinEarningRate.Star.deserializeBinaryFromReader);
      msg.setStarLv1(value);
      break;
    case 3:
      var value = new proto.jp.singcolor.CoinEarningRate.Star;
      reader.readMessage(value,proto.jp.singcolor.CoinEarningRate.Star.deserializeBinaryFromReader);
      msg.setStarLv2(value);
      break;
    case 4:
      var value = new proto.jp.singcolor.CoinEarningRate.Star;
      reader.readMessage(value,proto.jp.singcolor.CoinEarningRate.Star.deserializeBinaryFromReader);
      msg.setStarLv3(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.CoinEarningRate.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.jp.singcolor.CoinEarningRate.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.CoinEarningRate} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.CoinEarningRate.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getWebCoinPurchaseRate();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getStarLv1();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.jp.singcolor.CoinEarningRate.Star.serializeBinaryToWriter
    );
  }
  f = message.getStarLv2();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.jp.singcolor.CoinEarningRate.Star.serializeBinaryToWriter
    );
  }
  f = message.getStarLv3();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.jp.singcolor.CoinEarningRate.Star.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.jp.singcolor.CoinEarningRate.Star.prototype.toObject = function(opt_includeInstance) {
  return proto.jp.singcolor.CoinEarningRate.Star.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.jp.singcolor.CoinEarningRate.Star} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.CoinEarningRate.Star.toObject = function(includeInstance, msg) {
  var f, obj = {
    isEnabled: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    expiresAt: jspb.Message.getFieldWithDefault(msg, 2, 0),
    isExpired: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
    isRateSatisfied: jspb.Message.getBooleanFieldWithDefault(msg, 4, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.CoinEarningRate.Star}
 */
proto.jp.singcolor.CoinEarningRate.Star.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.jp.singcolor.CoinEarningRate.Star;
  return proto.jp.singcolor.CoinEarningRate.Star.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.CoinEarningRate.Star} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.CoinEarningRate.Star}
 */
proto.jp.singcolor.CoinEarningRate.Star.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsEnabled(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setExpiresAt(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsExpired(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsRateSatisfied(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.CoinEarningRate.Star.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.jp.singcolor.CoinEarningRate.Star.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.CoinEarningRate.Star} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.CoinEarningRate.Star.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIsEnabled();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getExpiresAt();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getIsExpired();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
  f = message.getIsRateSatisfied();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
};


/**
 * optional bool is_enabled = 1;
 * @return {boolean}
 */
proto.jp.singcolor.CoinEarningRate.Star.prototype.getIsEnabled = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.jp.singcolor.CoinEarningRate.Star} returns this
 */
proto.jp.singcolor.CoinEarningRate.Star.prototype.setIsEnabled = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional int64 expires_at = 2;
 * @return {number}
 */
proto.jp.singcolor.CoinEarningRate.Star.prototype.getExpiresAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.jp.singcolor.CoinEarningRate.Star} returns this
 */
proto.jp.singcolor.CoinEarningRate.Star.prototype.setExpiresAt = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional bool is_expired = 3;
 * @return {boolean}
 */
proto.jp.singcolor.CoinEarningRate.Star.prototype.getIsExpired = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.jp.singcolor.CoinEarningRate.Star} returns this
 */
proto.jp.singcolor.CoinEarningRate.Star.prototype.setIsExpired = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * optional bool is_rate_satisfied = 4;
 * @return {boolean}
 */
proto.jp.singcolor.CoinEarningRate.Star.prototype.getIsRateSatisfied = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.jp.singcolor.CoinEarningRate.Star} returns this
 */
proto.jp.singcolor.CoinEarningRate.Star.prototype.setIsRateSatisfied = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * optional string web_coin_purchase_rate = 1;
 * @return {string}
 */
proto.jp.singcolor.CoinEarningRate.prototype.getWebCoinPurchaseRate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.jp.singcolor.CoinEarningRate} returns this
 */
proto.jp.singcolor.CoinEarningRate.prototype.setWebCoinPurchaseRate = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional Star star_lv1 = 2;
 * @return {?proto.jp.singcolor.CoinEarningRate.Star}
 */
proto.jp.singcolor.CoinEarningRate.prototype.getStarLv1 = function() {
  return /** @type{?proto.jp.singcolor.CoinEarningRate.Star} */ (
    jspb.Message.getWrapperField(this, proto.jp.singcolor.CoinEarningRate.Star, 2));
};


/**
 * @param {?proto.jp.singcolor.CoinEarningRate.Star|undefined} value
 * @return {!proto.jp.singcolor.CoinEarningRate} returns this
*/
proto.jp.singcolor.CoinEarningRate.prototype.setStarLv1 = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.CoinEarningRate} returns this
 */
proto.jp.singcolor.CoinEarningRate.prototype.clearStarLv1 = function() {
  return this.setStarLv1(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.CoinEarningRate.prototype.hasStarLv1 = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional Star star_lv2 = 3;
 * @return {?proto.jp.singcolor.CoinEarningRate.Star}
 */
proto.jp.singcolor.CoinEarningRate.prototype.getStarLv2 = function() {
  return /** @type{?proto.jp.singcolor.CoinEarningRate.Star} */ (
    jspb.Message.getWrapperField(this, proto.jp.singcolor.CoinEarningRate.Star, 3));
};


/**
 * @param {?proto.jp.singcolor.CoinEarningRate.Star|undefined} value
 * @return {!proto.jp.singcolor.CoinEarningRate} returns this
*/
proto.jp.singcolor.CoinEarningRate.prototype.setStarLv2 = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.CoinEarningRate} returns this
 */
proto.jp.singcolor.CoinEarningRate.prototype.clearStarLv2 = function() {
  return this.setStarLv2(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.CoinEarningRate.prototype.hasStarLv2 = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional Star star_lv3 = 4;
 * @return {?proto.jp.singcolor.CoinEarningRate.Star}
 */
proto.jp.singcolor.CoinEarningRate.prototype.getStarLv3 = function() {
  return /** @type{?proto.jp.singcolor.CoinEarningRate.Star} */ (
    jspb.Message.getWrapperField(this, proto.jp.singcolor.CoinEarningRate.Star, 4));
};


/**
 * @param {?proto.jp.singcolor.CoinEarningRate.Star|undefined} value
 * @return {!proto.jp.singcolor.CoinEarningRate} returns this
*/
proto.jp.singcolor.CoinEarningRate.prototype.setStarLv3 = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.CoinEarningRate} returns this
 */
proto.jp.singcolor.CoinEarningRate.prototype.clearStarLv3 = function() {
  return this.setStarLv3(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.CoinEarningRate.prototype.hasStarLv3 = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.jp.singcolor.WebCoinBankTransfer.prototype.toObject = function(opt_includeInstance) {
  return proto.jp.singcolor.WebCoinBankTransfer.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.jp.singcolor.WebCoinBankTransfer} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.WebCoinBankTransfer.toObject = function(includeInstance, msg) {
  var f, obj = {
    account: (f = msg.getAccount()) && proto.jp.singcolor.StripeVbanAccount.toObject(includeInstance, f),
    remainingAmount: jspb.Message.getFieldWithDefault(msg, 2, 0),
    amount: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.WebCoinBankTransfer}
 */
proto.jp.singcolor.WebCoinBankTransfer.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.jp.singcolor.WebCoinBankTransfer;
  return proto.jp.singcolor.WebCoinBankTransfer.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.WebCoinBankTransfer} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.WebCoinBankTransfer}
 */
proto.jp.singcolor.WebCoinBankTransfer.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.jp.singcolor.StripeVbanAccount;
      reader.readMessage(value,proto.jp.singcolor.StripeVbanAccount.deserializeBinaryFromReader);
      msg.setAccount(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setRemainingAmount(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setAmount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.WebCoinBankTransfer.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.jp.singcolor.WebCoinBankTransfer.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.WebCoinBankTransfer} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.WebCoinBankTransfer.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAccount();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.jp.singcolor.StripeVbanAccount.serializeBinaryToWriter
    );
  }
  f = message.getRemainingAmount();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getAmount();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
};


/**
 * optional StripeVbanAccount account = 1;
 * @return {?proto.jp.singcolor.StripeVbanAccount}
 */
proto.jp.singcolor.WebCoinBankTransfer.prototype.getAccount = function() {
  return /** @type{?proto.jp.singcolor.StripeVbanAccount} */ (
    jspb.Message.getWrapperField(this, proto.jp.singcolor.StripeVbanAccount, 1));
};


/**
 * @param {?proto.jp.singcolor.StripeVbanAccount|undefined} value
 * @return {!proto.jp.singcolor.WebCoinBankTransfer} returns this
*/
proto.jp.singcolor.WebCoinBankTransfer.prototype.setAccount = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.WebCoinBankTransfer} returns this
 */
proto.jp.singcolor.WebCoinBankTransfer.prototype.clearAccount = function() {
  return this.setAccount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.WebCoinBankTransfer.prototype.hasAccount = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional int64 remaining_amount = 2;
 * @return {number}
 */
proto.jp.singcolor.WebCoinBankTransfer.prototype.getRemainingAmount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.jp.singcolor.WebCoinBankTransfer} returns this
 */
proto.jp.singcolor.WebCoinBankTransfer.prototype.setRemainingAmount = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 amount = 3;
 * @return {number}
 */
proto.jp.singcolor.WebCoinBankTransfer.prototype.getAmount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.jp.singcolor.WebCoinBankTransfer} returns this
 */
proto.jp.singcolor.WebCoinBankTransfer.prototype.setAmount = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.jp.singcolor.StripeVbanAccount.prototype.toObject = function(opt_includeInstance) {
  return proto.jp.singcolor.StripeVbanAccount.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.jp.singcolor.StripeVbanAccount} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.StripeVbanAccount.toObject = function(includeInstance, msg) {
  var f, obj = {
    bankName: jspb.Message.getFieldWithDefault(msg, 1, ""),
    bankCode: jspb.Message.getFieldWithDefault(msg, 2, ""),
    branchName: jspb.Message.getFieldWithDefault(msg, 3, ""),
    branchCode: jspb.Message.getFieldWithDefault(msg, 4, ""),
    accountType: jspb.Message.getFieldWithDefault(msg, 5, 0),
    accountNumber: jspb.Message.getFieldWithDefault(msg, 6, ""),
    accountHolderName: jspb.Message.getFieldWithDefault(msg, 7, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.StripeVbanAccount}
 */
proto.jp.singcolor.StripeVbanAccount.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.jp.singcolor.StripeVbanAccount;
  return proto.jp.singcolor.StripeVbanAccount.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.StripeVbanAccount} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.StripeVbanAccount}
 */
proto.jp.singcolor.StripeVbanAccount.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setBankName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setBankCode(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setBranchName(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setBranchCode(value);
      break;
    case 5:
      var value = /** @type {!proto.jp.singcolor.ZenginBankAccountType} */ (reader.readEnum());
      msg.setAccountType(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountNumber(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountHolderName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.StripeVbanAccount.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.jp.singcolor.StripeVbanAccount.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.StripeVbanAccount} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.StripeVbanAccount.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBankName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getBankCode();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getBranchName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getBranchCode();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getAccountType();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
  f = message.getAccountNumber();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getAccountHolderName();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
};


/**
 * optional string bank_name = 1;
 * @return {string}
 */
proto.jp.singcolor.StripeVbanAccount.prototype.getBankName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.jp.singcolor.StripeVbanAccount} returns this
 */
proto.jp.singcolor.StripeVbanAccount.prototype.setBankName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string bank_code = 2;
 * @return {string}
 */
proto.jp.singcolor.StripeVbanAccount.prototype.getBankCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.jp.singcolor.StripeVbanAccount} returns this
 */
proto.jp.singcolor.StripeVbanAccount.prototype.setBankCode = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string branch_name = 3;
 * @return {string}
 */
proto.jp.singcolor.StripeVbanAccount.prototype.getBranchName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.jp.singcolor.StripeVbanAccount} returns this
 */
proto.jp.singcolor.StripeVbanAccount.prototype.setBranchName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string branch_code = 4;
 * @return {string}
 */
proto.jp.singcolor.StripeVbanAccount.prototype.getBranchCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.jp.singcolor.StripeVbanAccount} returns this
 */
proto.jp.singcolor.StripeVbanAccount.prototype.setBranchCode = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional ZenginBankAccountType account_type = 5;
 * @return {!proto.jp.singcolor.ZenginBankAccountType}
 */
proto.jp.singcolor.StripeVbanAccount.prototype.getAccountType = function() {
  return /** @type {!proto.jp.singcolor.ZenginBankAccountType} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.jp.singcolor.ZenginBankAccountType} value
 * @return {!proto.jp.singcolor.StripeVbanAccount} returns this
 */
proto.jp.singcolor.StripeVbanAccount.prototype.setAccountType = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};


/**
 * optional string account_number = 6;
 * @return {string}
 */
proto.jp.singcolor.StripeVbanAccount.prototype.getAccountNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.jp.singcolor.StripeVbanAccount} returns this
 */
proto.jp.singcolor.StripeVbanAccount.prototype.setAccountNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string account_holder_name = 7;
 * @return {string}
 */
proto.jp.singcolor.StripeVbanAccount.prototype.getAccountHolderName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.jp.singcolor.StripeVbanAccount} returns this
 */
proto.jp.singcolor.StripeVbanAccount.prototype.setAccountHolderName = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * @enum {number}
 */
proto.jp.singcolor.MembershipType = {
  MEMBERSHIP_TYPE_1_MONTH: 0,
  MEMBERSHIP_TYPE_3_MONTHS: 1,
  MEMBERSHIP_TYPE_6_MONTHS: 2
};

goog.object.extend(exports, proto.jp.singcolor);
