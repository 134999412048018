// source: zengin_entity.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

goog.exportSymbol('proto.jp.singcolor.ZenginBankAccountType', null, global);
/**
 * @enum {number}
 */
proto.jp.singcolor.ZenginBankAccountType = {
  ZENGIN_BANK_ACCOUNT_TYPE_UNKNOWN: 0,
  ZENGIN_BANK_ACCOUNT_TYPE_ORDINARY_DEPOSIT: 1,
  ZENGIN_BANK_ACCOUNT_TYPE_CHECKING: 2,
  ZENGIN_BANK_ACCOUNT_TYPE_SAVINGS: 4,
  ZENGIN_BANK_ACCOUNT_TYPE_OTHER: 9
};

goog.object.extend(exports, proto.jp.singcolor);
